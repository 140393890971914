import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
// import { Link } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { useStaticQuery, graphql, Link } from "gatsby";
import { FaArrowLeft } from "react-icons/fa";
import { Release as ReleaseType } from "../data/index";

import ReleaseLinks from "../components/release-links";
import { formatMonthOrDay } from "../utils";
// import { coversQuery } from "../data";

const coversQuery = graphql`
  query {
    nice: file(relativePath: { eq: "nice.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    atHome: file(relativePath: { eq: "at-home.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    boesmanBlues: file(relativePath: { eq: "boesman-blues.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    trioStyle: file(relativePath: { eq: "volume 2 - trio style.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imIn: file(relativePath: { eq: "I'm in cover square.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stepByStep: file(relativePath: { eq: "step by step big.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lawnmowerEp: file(relativePath: { eq: "lawnmower ep.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    home: file(relativePath: { eq: "home.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ruse: file(relativePath: { eq: "ruse.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pretext: file(relativePath: { eq: "pretext.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mssapessm: file(relativePath: { eq: "Mssapessm.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beatTapes: file(relativePath: { eq: "beat tapes.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fragments: file(relativePath: { eq: "fragments.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hechoEnMexico: file(relativePath: { eq: "hecho en mexico.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    anecdotes: file(relativePath: { eq: "anecdotes.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    characters: file(relativePath: { eq: "characters.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jetLag: file(relativePath: { eq: "jet lag.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ohb: file(relativePath: { eq: "ohb.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    promisedLand: file(relativePath: { eq: "promised land ep.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    theBeautifulMadness: file(
      relativePath: { eq: "the beautiful madness.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    justActNormal: file(relativePath: { eq: "just_act_normal.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    darkHalo: file(relativePath: { eq: "dark halo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    songForMyFutureWife: file(
      relativePath: { eq: "song_for_my_future_wife.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whatAmIDoing: file(relativePath: { eq: "what am i doing.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Release = ({ pageContext }: { pageContext: ReleaseType }) => {
  const data = useStaticQuery(coversQuery);

  return (
    <Layout>
      <SEO title={pageContext.title} />

      <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 1.4rem;
        `}
      >
        <Link
          to="/music/"
          css={css`
            display: flex;
            align-items: center;
            color: black;
          `}
        >
          <FaArrowLeft
            size={35}
            css={css`
              margin-right: 16px;
              color: rgba(0, 0, 0, 0.8);
            `}
          />
        </Link>
        <h1
          css={css`
            margin: 0;
          `}
        >
          {pageContext.title}
        </h1>
      </div>

      <div>
        <Img
          css={css`
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            width: 450px;
            max-width: calc(100vw - 2.175rem);
            margin-bottom: 16px;
          `}
          fluid={data[pageContext.imageKey].childImageSharp.fluid}
        />
        <ReleaseLinks release={pageContext} iconSize={32} />
        <h3
          css={css`
            margin-top: 16px;
            margin-bottom: 8px;
          `}
        >
          {`${pageContext.artist} • ${pageContext.title}`}
        </h3>
        <div
          css={css`
            margin-bottom: 16px;
          `}
        >{`${pageContext.released.year}/${formatMonthOrDay(
          pageContext.released.month
        )}/${formatMonthOrDay(pageContext.released.day)}`}</div>

        {pageContext.description && (
          <div
            css={css`
              margin-bottom: 16px;
            `}
            dangerouslySetInnerHTML={{ __html: pageContext.description }}
          />
        )}
        <div>Involvement: {pageContext.credits}</div>
      </div>
    </Layout>
  );
};

export default Release;
